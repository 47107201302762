import React from "react"
import { graphql } from "gatsby"
import "./base-styles.scss"
import "./LayoutCommon"
import { baseClass, childClass } from "./LayoutCommon";
import SiteHeader from "components/headers/SiteHeader";
import { Mdx } from "@GraphQLModels";
import MdxBlock from "components/texty/MdxBlock";
import SiteFooter from "components/footer/SiteFooter";
import PageMeta from "components/texty/PageMeta";


interface IProps {
  pageContext: {
    id: string
  };
  data: {
    mdx: Mdx,
  }
}

export default function GuffPageLayout(props: IProps) {

  return (
    <div className={baseClass}>
      <PageMeta
        title={props.data.mdx.frontmatter.title}
        route={props.data.mdx.fields.route}>
      </PageMeta>
      <SiteHeader />
      <MdxBlock mdx={props.data.mdx}
        className={[childClass('container', 'reading-mode'), childClass('relative')].join(' ')}
        showTitle={true} />
      <SiteFooter />
    </div>
  )
}

/**
 * Query for data for the page. Note that $id is injected in via context from
 * actions.createPage. See gatsby-node.js for more info.
 */
export const pageQuery = graphql`
  query GuffPageLayoutQuery($id: String) {
      mdx(id: {eq: $id }) {
        id
        fields {
          route
        }
        frontmatter {
          title
        }
        code {
          body
        }
      }
  }
  `

